export const generoData = [
  {
    "value": "",
    "label": "Selecione"
  },
  {
    "value": "M",
    "label": "Masculino"
  },
  {
    "value": "F",
    "label": "Feminino"
  }
]


export const orgaoEmissorData = [
  {
    "value": "",
    "label": "Selecione"
  },
  {
    "value": "SSP",
    "label": "Secretaria de Segurança Pública"
  },
  {
    "value": "PM",
    "label": "Polícia Militar"
  },
  {
    "value": "PC",
    "label": "Policia Civil"
  },
  {
    "value": "CNT",
    "label": "Carteira Nacional de Habilitação"
  },
  {
    "value": "DIC",
    "label": "Diretoria de Identificação Civil"
  },
  {
    "value": "CTPS",
    "label": "Carteira de Trabaho e Previdência Social"
  },
  {
    "value": "FGTS",
    "label": "Fundo de Garantia do Tempo de Serviço"
  },
  {
    "value": "IFP",
    "label": "Instituto Félix Pacheco"
  },
  {
    "value": "IPF",
    "label": "Instituto Pereira Faustino"
  },
  {
    "value": "IML",
    "label": "Instituto Médico-Legal"
  },
  {
    "value": "MTE",
    "label": "Ministério do Trabalho e Emprego"
  },
  {
    "value": "MMA",
    "label": "Ministério da Marinha"
  },
  {
    "value": "MAE",
    "label": "Ministério da Aeronáutica"
  },
  {
    "value": "MEX",
    "label": "Ministério do Exército"
  },
  {
    "value": "POF",
    "label": "Polícia Federal"
  },
  {
    "value": "POM",
    "label": "Polícia Militar"
  },
  {
    "value": "SES",
    "label": "Carteira de Estrangeiro"
  },
  {
    "value": "SJS",
    "label": "Secretaria da Justiça e Segurança"
  },
  {
    "value": "SJTS",
    "label": "Secretaria da Justiça do Trabalho e Segurança"
  },
  {
    "value": "ZZZ",
    "label": "Outros (inclusive exterior)"
  }
]

export const getValues = () =>  ({
  Nome: "",
  OrgaoEmissor: "",
  Genero: ""
})