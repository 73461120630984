import styled from 'styled-components'

export const ErrorMessage = styled.span`
  margin-bottom: 6px;
  color: crimson;

  :before {
    content: '*';
  }

`